.footer {
  font-family: 'Ruda';
  font-weight: 800;  
  background-color: var(--color-background);
  color: var(--color-text);
  text-align: center;
  padding: var(--spacing-8);
}

.footer p {
  font-size: var(--font-size-xs);
}
