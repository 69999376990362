@import './variables.css';

/* src/index.css */
body {
  padding: 0 5%;
  box-sizing: border-box;
}

.app-page {
  max-width: 1200px;
}

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
  }
}