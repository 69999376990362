/* src/Header.css */
.header {
    font-family: 'Ruda';
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
  }

  .logo-container {
    display: flex;
    align-items: center;
  }

  .header-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .header-phone-menu {
    width: 50px;
    height: 50px;
  }

  .header h4 {
    color: #333;
    font-size: 16px;
    text-align: left;
  }
  
  .menu-toggle {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }

  .header-logo-link {
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    .nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
  
    .nav.open {
      display: flex;
      text-align: right;
    }
  
    .nav ul {
      flex-direction: column;
      gap: 10px;
      padding-right: 20px;
    }
  
    .nav ul li a {
      padding: 10px;
    }
  }
  