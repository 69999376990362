/* src/Faq.css */
.faq {
  padding: var(--spacing-16) 0;
}

.faq h2 {
  font-size: var(--font-size-4xl);
  text-align: center;
  margin-bottom: var(--spacing-8);
}

.faq-item {
  border: 1px solid var(--color-border);
  margin-bottom: var(--spacing-4);
  cursor: pointer;
}

.faq-question {
  padding: var(--spacing-2) var(--spacing-6);
  background: var(--color-background-alt);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  text-align: left;
}

.faq-question span {
  font-size: var(--font-size-xl);
}

.faq-answer {
  padding: 0 var(--spacing-6);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  font-size: var(--font-size-base);
  color: var(--color-text);
  text-align: left;
}

.faq-item.active .faq-answer {
  padding: var(--spacing-4) var(--spacing-6);
  max-height: 500px;
}

.faq-item.active .faq-question {
  background: var(--color-primary-light);
}

@media (max-width: 768px) {
  .faq h2 {
    font-size: var(--font-size-2xl);
  }

  .faq-question span {
    font-size: var(--font-size-base);
  }
}