/* src/ServicesGrid.css */
.services-grid {
    padding: var(--spacing-4) var(--spacing-4);
    text-align: center;
  }
  
  .services-grid h2 {
    margin-bottom: var(--spacing-16);
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-6);
  }
  
  .grid-item {
    background-color: var(--color-secondary);
    padding: var(--spacing-6);
    border-radius: var(--border-radius);
    color: var(--color-background);
    box-shadow: 0 var(--spacing-2) var(--spacing-4) rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
  }
  
  .grid-item h3 {
    font-size: var(--font-size-xl);
    text-align: center;
    margin-bottom: var(--spacing-4);
  }
  
  .grid-item p {
    font-size: var(--font-size-lg);
    text-align: left;
    color: var(--color-background);
    margin-bottom: var(--spacing-6);
  }
  
  .read-more {    
    display: inline-block;
    padding: var(--spacing-2) var(--spacing-8);
    background-color: var(--color-primary);
    color: var(--color-background);
    text-decoration: none;
    border-radius: var(--border-radius-full);
  }
  
  .read-more:hover {
    background-color: var(--color-primary-dark);
  }

  @media (max-width: 768px) {
    .grid-item h3 {
        font-size: var(--font-size-lg);
      }
      
      .grid-item p {
        font-size: var(--font-size-base);
      }    
  }