/* Services.css */

.services-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-16) var(--spacing-4);
  font-family: 'Poppins', sans-serif;  
  background-color: var(--color-background);
}

.services-page h1 {
  font-size: var(--font-size-4xl);
  text-align: center;
  margin-bottom: var(--spacing-4);
  font-weight: 800;
  color: var(--color-text);
}

.services-page > p {
  font-size: var(--font-size-lg);
  line-height: 1.6;
  text-align: justify;
  margin-bottom: var(--spacing-16);
}

.service-section {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-24);
  color: var(--color-text);
}

.service-content,
.service-image {
  flex: 1;
  width: 50%;
}

.service-content {
  padding: var(--spacing-8);
}

.service-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 var(--spacing-1) var(--spacing-2) rgba(0, 0, 0, 0.1);
}

.service-content h2 {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-2);
}

.service-content h3 {
  font-size: var(--font-size-lg);
  color: var(--color-text-light);
  margin-bottom: var(--spacing-4);
}

.service-content p {
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--color-text-light);
  text-align: justify;
}

.service-section ul {
  list-style: none;
  padding-left: 0;
}

.service-section li {
  font-size: var(--font-size-base);
  color: var(--color-text-light);
  margin-bottom: var(--spacing-2);
  padding-left: var(--spacing-6);
  position: relative;
}

.service-section li::before {
  content: "✓";
  font-size: var(--font-size-lg);
  position: absolute;
  left: 0;
  top: -2px;
}

@media (max-width: 768px) {
  .services-page h1 {
    font-size: var(--font-size-3xl);
  }

  .services-page > p {
    font-size: var(--font-size-base);
  }

  .service-section {
    flex-direction: column;
  }

  .service-content,
  .service-image {
    width: 100%;
  }

  .service-image {
    order: -1;
  }

  .service-content {
    padding: var(--spacing-4) 0;
  }

  .service-section h2 {
    font-size: var(--font-size-2xl);
  }

  .service-section p,
  .service-section li {
    font-size: var(--font-size-sm);
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(var(--spacing-4)); }
  to { opacity: 1; transform: translateY(0); }
}
  
.services-page {
  animation: fadeIn 0.8s ease-out forwards;
}
