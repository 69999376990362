.about-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-16) var(--spacing-4);
  background-color: var(--color-background);
}

.about-page h1 {
  font-size: var(--font-size-4xl);
  color: var(--color-text);
  text-align: center;
}

.vision, .team-story {
  background: linear-gradient(135deg, var(--color-background), var(--color-background-alt));
  border-radius: var(--border-radius-md);
  padding: var(--spacing-12);
  margin-bottom: var(--spacing-12);
  box-shadow: 0 var(--spacing-3) var(--spacing-4) -5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.vision:hover, .team-story:hover {
  transform: translateY(-5px);
  box-shadow: 0 var(--spacing-4) var(--spacing-8) -5px rgba(0, 0, 0, 0.15);
}

.vision h2, .team-story h2 {
  font-size: var(--font-size-3xl);
  color: var(--color-text);
  margin-bottom: var(--spacing-4);
  position: relative;
  display: inline-block;
}

.vision p, .team-story p {
  font-size: var(--font-size-base);
  color: var(--color-text);
  line-height: 1.8;
  margin-bottom: var(--spacing-4);
  text-align: justify;
}

.cta-button {
  display: inline-block;
  background: linear-gradient(135deg, var(--color-primary), var(--color-primary-dark));
  color: var(--color-background);
  padding: var(--spacing-3) var(--spacing-6);
  border-radius: var(--border-radius-full);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 var(--spacing-1) var(--spacing-2) rgba(59, 130, 246, 0.3);
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 var(--spacing-2) var(--spacing-2) rgba(59, 130, 246, 0.4);
  background: linear-gradient(135deg, var(--color-primary-dark), var(--color-primary));
}

.team-story {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
}

.team-image {
  flex: 1;
  max-width: 50%;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-sm);
}

.team-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.team-image:hover img {
  transform: scale(1.05);
}

.team-content {
  flex: 1;
  max-width: 50%;
}

@media (max-width: 768px) {
  .about-page h1 {
    font-size: var(--font-size-3xl);
  }

  .vision h2, .team-story h2 {
    font-size: var(--font-size-2xl);
  }

  .vision p, .team-story p {
    font-size: var(--font-size-base);
  }

  .team-story {
    flex-direction: column;
  }

  .team-image,
  .team-content {
    max-width: 100%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(var(--spacing-4)); }
  to { opacity: 1; transform: translateY(0); }
}

.vision, .team-story {
  animation: fadeIn 0.8s ease-out forwards;
}

.team-story {
  animation-delay: 0.2s;
}