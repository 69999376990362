.benefits-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-16) var(--spacing-4);
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-background);
}

.benefits-header {
  text-align: center;
  margin-bottom: var(--spacing-16);
}

.benefits-header h1 {
  font-size: var(--font-size-4xl);
  color: var(--color-text);
  margin-bottom: var(--spacing-4);
  font-weight: 700;
}

.benefits-header p {
  font-size: var(--font-size-xl);
  color: var(--color-text-light);
  max-width: 600px;
  margin: 0 auto;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-8);
}

.benefit-card {
  background: linear-gradient(135deg, var(--color-background), var(--color-background-alt));
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-8);
  box-shadow: 0 var(--spacing-3) var(--spacing-4) -3px rgba(0, 0, 0, 0.1), 0 var(--spacing-1) var(--spacing-2) -2px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.benefit-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 var(--spacing-4) var(--spacing-6) -5px rgba(0, 0, 0, 0.1), 0 var(--spacing-3) var(--spacing-3) -5px rgba(0, 0, 0, 0.04);
  background: linear-gradient(135deg, var(--color-background-alt), var(--color-background));
}

.benefit-icon {
  font-size: var(--font-size-4xl);
  margin-bottom: var(--spacing-4);
  background: linear-gradient(135deg, var(--color-primary), var(--color-primary-dark));
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-full);
  color: var(--color-background);
  box-shadow: 0 var(--spacing-1) var(--spacing-2) rgba(0, 0, 0, 0.1);
}

.benefit-card h2 {
  font-size: var(--font-size-xl);
  color: var(--color-primary-dark);
  margin-bottom: var(--spacing-4);
  font-weight: 600;
}

.benefit-card p {
  font-size: var(--font-size-base);
  color: var(--color-text-light);
  line-height: 1.6;
}

@media (max-width: 768px) {
  .benefits-header h1 {
    font-size: var(--font-size-3xl);
  }

  .benefits-header p {
    font-size: var(--font-size-base);
  }

  .benefit-card h2 {
    font-size: var(--font-size-lg);
  }

  .benefit-card p {
    font-size: var(--font-size-sm);
  }
}

/* Add some animation to the cards */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(var(--spacing-4));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.benefit-card {
  animation: fadeInUp 0.5s ease-out forwards;
  opacity: 0;
}

.benefit-card:nth-child(1) { animation-delay: 0.1s; }
.benefit-card:nth-child(2) { animation-delay: 0.2s; }
.benefit-card:nth-child(3) { animation-delay: 0.3s; }
.benefit-card:nth-child(4) { animation-delay: 0.4s; }
.benefit-card:nth-child(5) { animation-delay: 0.5s; }
.benefit-card:nth-child(6) { animation-delay: 0.6s; }
.benefit-card:nth-child(7) { animation-delay: 0.7s; }
.benefit-card:nth-child(8) { animation-delay: 0.8s; }

/* Add different gradient backgrounds to each card */
.benefit-card:nth-child(1) { background: linear-gradient(135deg, #ffffff, #e0f2fe); }
.benefit-card:nth-child(2) { background: linear-gradient(135deg, #ffffff, #e0e7ff); }
.benefit-card:nth-child(3) { background: linear-gradient(135deg, #ffffff, #fae8ff); }
.benefit-card:nth-child(4) { background: linear-gradient(135deg, #ffffff, #ffedd5); }
.benefit-card:nth-child(5) { background: linear-gradient(135deg, #ffffff, #dcfce7); }
.benefit-card:nth-child(6) { background: linear-gradient(135deg, #ffffff, #f0fdf4); }
.benefit-card:nth-child(7) { background: linear-gradient(135deg, #ffffff, #f0f9ff); }
.benefit-card:nth-child(8) { background: linear-gradient(135deg, #ffffff, #eff6ff); }

/* Add hover effect with slightly darker gradients */
.benefit-card:nth-child(1):hover { background: linear-gradient(135deg, #e0f2fe, #bae6fd); }
.benefit-card:nth-child(2):hover { background: linear-gradient(135deg, #e0e7ff, #c7d2fe); }
.benefit-card:nth-child(3):hover { background: linear-gradient(135deg, #fae8ff, #f5d0fe); }
.benefit-card:nth-child(4):hover { background: linear-gradient(135deg, #ffedd5, #fed7aa); }
.benefit-card:nth-child(5):hover { background: linear-gradient(135deg, #dcfce7, #bbf7d0); }
.benefit-card:nth-child(6):hover { background: linear-gradient(135deg, #f0fdf4, #d9f99d); }
.benefit-card:nth-child(7):hover { background: linear-gradient(135deg, #f0f9ff, #bae6fd); }
.benefit-card:nth-child(8):hover { background: linear-gradient(135deg, #eff6ff, #bfdbfe); }