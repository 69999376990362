/* src/Home.css */
  .home {
    padding: var(--spacing-4) var(--spacing-6);
    background-color: var(--color-background);
    text-align: center;
    color: var(--color-text);
  }
  
  .home h1 {
    font-size: var(--font-size-4xl);
    font-weight: 700;
    padding-top: var(--spacing-4);
    color: var(--color-text);
  }

  .home h2 {
    font-size: var(--font-size-2xl);
    margin-bottom: var(--spacing-6);
    color: var(--color-text);
  }
  
  .home h3 {
    font-size: var(--font-size-xl);
    font-weight: 700;
    padding-top: var(--spacing-2);
    border-radius: var(--border-radius);
  }  

  .home p {
    font-size: var(--font-size-base);
    color: var(--color-text);
    line-height: 1.5;
    text-align: justify;
  }
  
  .home img {
    border-radius: var(--border-radius);
    max-width: 100%;
  }

  .banner-strip {
    color: white;
    background-color: var(--color-secondary);
    padding: var(--spacing-2);
  }

  .why-vto {
    text-align: left;
  }

  .why-choose-us {
    text-align: center;
    background-color: var(--color-background-alt);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-6);
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .feature {
    flex: 1 1 35%;
    max-width: 45%;
    text-align: left;
    margin: 0 var(--spacing-6);
  }

  .feature p {
    font-size: var(--font-size-base);
    color: var(--color-text);
  }

  .steps {
    padding-left: 0;
    text-align: left;
    counter-reset: step-counter;
  }
  
  .steps li {
    margin-bottom: var(--spacing-6);
    display: flex;
    align-items: flex-start;
  }
  
  .steps li::before {
    content: counter(step-counter) ".";
    counter-increment: step-counter;
    margin-right: var(--spacing-4);
  }
  

  @media (max-width: 768px) {
    .home h1 {
      font-size: var(--font-size-2xl);
      padding-top: var(--spacing-4);
    }

    .home h3 {
      font-size: var(--font-size-lg);
      padding-top: var(--spacing-2);
    }

    .why-choose-us {
      text-align: center;
      padding: var(--spacing-6);
    }  

    .features {
      flex-direction: column;
      align-items: center;
    }

    .feature {
      max-width: 100%;
      margin: 0;
    }        
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(var(--spacing-4)); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .home {
    animation: fadeIn 0.8s ease-out forwards;
  }

  .services-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns always */
    gap: 1rem; /* optional spacing between items */
    max-width: 600px; /* optional max width */
    margin: 0 auto; /* center the grid if you want */
  }
  
  .service-grid-image {
    display: block; /* ensures no extra whitespace around images */
    width: 100%;
    transition: transform 0.3s ease;
  }

  .service-grid-image:hover {
    transform: scale(1.05);
  }