.contact-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-16) var(--spacing-4);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-8);
  background-color: var(--color-background);
}

.contact-info {
  flex: 1;
  min-width: 300px;
}

.contact-form-container {
  flex: 1;
  min-width: 300px;
}

.contact-page h1 {
  font-size: var(--font-size-4xl);
  color: var(--color-text);
  margin-bottom: var(--spacing-8);
  width: 100%;
}

.contact-page p {
  font-size: var(--font-size-base);
  color: var(--color-text-light);
  line-height: 1.6;
  margin-bottom: var(--spacing-8);
  text-align: justify;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.contact-form input,
.contact-form textarea {
  padding: var(--spacing-3);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background: linear-gradient(135deg, var(--color-primary), var(--color-primary-dark));
  color: var(--color-background);
  padding: var(--spacing-3) var(--spacing-6);
  border: none;
  border-radius: var(--border-radius-full);
  font-size: var(--font-size-base);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 var(--spacing-1) var(--spacing-2) rgba(59, 130, 246, 0.3);
}

.submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 var(--spacing-2) var(--spacing-3) rgba(59, 130, 246, 0.4);
  background: linear-gradient(135deg, var(--color-primary-dark), var(--color-primary-dark));
}

.submit-button:disabled {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .contact-page {
    flex-direction: column;
  }

  .contact-page h1 {
    font-size: var(--font-size-3xl);
  }

  .contact-page p {
    font-size: var(--font-size-base);
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(var(--spacing-4)); }
  to { opacity: 1; transform: translateY(0); }
}

.contact-page {
  animation: fadeIn 0.8s ease-out forwards;
}